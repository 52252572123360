// Bootstrap variables:
// https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss

$theme-colors: (
  "dark": #444,
  "primary": #C0C0C0,
  "secondary": #555555,
  "danger": #E0380A,
  "warning": #F48416
);

$border-radius:    .1rem !default;
$border-radius-sm: .07rem !default;
$border-radius-lg: .15rem !default;

$card-border-radius: .3rem;
$card-border-width: 0;

$input-border-width: 0;
