@import './styles/variables';
@import '~bootstrap/scss/bootstrap.scss';

body {
  background: #ddd;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.auth-loading, .data-loading {
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  & .auth-loading-message, & .data-loading-message {
    position: relative;
    top: 50%;
    margin: auto;
    transform: translateY(-100%);
    color: white;
  }
}


.group {
  position: relative;
  z-index: 1;
}


.target-note.note-card {
  border: 3px solid #ccc!important;
}

.note-card {
  & .icons {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #bbb;
    right: 10px;
  }
}

.note-card {
  max-width: 300px;
  min-width: 200px;
  margin: 0 auto 20px auto;
  position: relative;
  z-index: 2;
  line-height: 17px;
  font-size: 14px;
  border-radius: 0;
  background: white!important;
  user-select: none;
  &.disabled {
    background: #ddd!important;
  }
}
