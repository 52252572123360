.react-kanban-board {
  padding: 15px;
  display: block !important;
}

#board-droppable {
  // display: flex;
  // flex-direction: column;
}

div[data-rbd-draggable-context-id] {
  overflow: hidden;
  & div[data-rbd-draggable-context-id] {
    width: 275px !important;
    float: left;
  }
}

.react-kanban-card {
  @extend .react-kanban-card-skeleton;
  border-radius: 1px;
  background-color: #fff;
  padding: 10px;
  margin: 20px;
  // box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  // float: left;
  display: inline-block;
}

.react-kanban-card-skeleton {
  box-sizing: border-box;
  width: 100%;
  width: 230px;
  height: 230px;
}

.react-kanban-card--dragging {
  box-shadow: 2px 2px grey;
}

.react-kanban-card__description {
  padding-top: 10px;
}

.react-kanban-card__title {
  border-bottom: 0px;
  padding-bottom: 5px;
  font-size: 12px;
  color: #777;
  display: flex;
  justify-content: space-between;
}

.react-kanban-column {
  width: 100%!important;
  display: block!important;
  padding: 15px;
  border-radius: 2px;
  background-color: #fff;
  margin-bottom: 25px;
  input {
    &:focus {
      outline: none;
    }
  }
}

.react-kanban-card-adder-form {
  @extend .react-kanban-card-skeleton;
  border-radius: 3px;
  background-color: #fff;
  padding: 10px;
  margin-bottom: 7px;
  input {
    border: 0px;
    font-family: inherit;
    font-size: inherit;
  }
}

.react-kanban-card-adder-button {
  width: 100%;
  margin-top: 5px;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid #ccc;
  transition: 0.3s;
  border-radius: 3px;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  &:hover {
    background-color: #ccc;
  }
}

.react-kanban-card-adder-form__title {
  font-weight: bold;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px;
  &:focus {
    outline: none;
  }
}

.react-kanban-card-adder-form__description {
  width: 100%;
  margin-top: 10px;
  &:focus {
    outline: none;
  }
}

.react-kanban-card-adder-form__button {
  background-color: #eee;
  border: none;
  padding: 5px;
  width: 45%;
  margin-top: 5px;
  border-radius: 3px;
  &:hover {
    transition: 0.3s;
    cursor: pointer;
    background-color: #ccc;
  }
}

.react-kanban-column-header {
  padding-bottom: 10px;
  // font-weight: bold;
  // background: #eee;
  border-bottom: 1px solid #eee;

  input {
    width: calc(100% - 150px);
    &:focus {
      outline: none;
    }
  }
}

.react-kanban-column-header__button {
  color: #333333;
  background-color: #ffffff;
  border-color: #cccccc;

  &:hover,
  &:focus,
  &:active {
    background-color: #e6e6e6;
  }
}

.react-kanban-column-adder-button {
  border: 2px dashed #eee;
  height: 132px;
  margin: 5px;

  &:hover {
    cursor: pointer;
  }
}
